exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-management-js": () => import("./../../../src/pages/en/about-us/management.js" /* webpackChunkName: "component---src-pages-en-about-us-management-js" */),
  "component---src-pages-en-about-us-team-associates-js": () => import("./../../../src/pages/en/about-us/team/associates.js" /* webpackChunkName: "component---src-pages-en-about-us-team-associates-js" */),
  "component---src-pages-en-about-us-team-js": () => import("./../../../src/pages/en/about-us/team.js" /* webpackChunkName: "component---src-pages-en-about-us-team-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-our-projects-js": () => import("./../../../src/pages/en/our-projects.js" /* webpackChunkName: "component---src-pages-en-our-projects-js" */),
  "component---src-pages-en-our-projects-strapi-project-slug-js": () => import("./../../../src/pages/en/our-projects/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-en-our-projects-strapi-project-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasze-projekty-js": () => import("./../../../src/pages/nasze-projekty.js" /* webpackChunkName: "component---src-pages-nasze-projekty-js" */),
  "component---src-pages-nasze-projekty-strapi-project-slug-js": () => import("./../../../src/pages/nasze-projekty/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-nasze-projekty-strapi-project-slug-js" */),
  "component---src-pages-o-nas-wladze-instytutu-js": () => import("./../../../src/pages/o-nas/wladze-instytutu.js" /* webpackChunkName: "component---src-pages-o-nas-wladze-instytutu-js" */),
  "component---src-pages-o-nas-zespol-js": () => import("./../../../src/pages/o-nas/zespol.js" /* webpackChunkName: "component---src-pages-o-nas-zespol-js" */),
  "component---src-pages-o-nas-zespol-wspolpracownicy-js": () => import("./../../../src/pages/o-nas/zespol/wspolpracownicy.js" /* webpackChunkName: "component---src-pages-o-nas-zespol-wspolpracownicy-js" */)
}

